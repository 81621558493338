import { Component, OnInit } from "@angular/core";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: "mdp-view-main",
  templateUrl: "./view-main.component.html",
  styleUrls: ["./view-main.component.scss"]
})
export class ViewMainComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
