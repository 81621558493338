import { Component } from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {InvalidCredentialsException} from "../../exceptions/auth.exceptions";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "mdp-view-login",
  templateUrl: "./view-login.component.html",
  styleUrls: ["./view-login.component.scss"]
})
export class ViewLoginComponent {

  error: string | null = null;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/";
    this.checkIfLoggedInAndMoveOn();
  }

  async onLogin({username, password}): Promise<void> {
    this.error = null;
    try {
      await this.authService.login(username, password);
    } catch (e) {
      if (e instanceof InvalidCredentialsException) {
        this.error = "Invalid credentials";
      }
    }

    this.checkIfLoggedInAndMoveOn();
  }

  checkIfLoggedInAndMoveOn(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate([this.returnUrl]);
    }
  }

}
