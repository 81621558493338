import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { InvalidCredentialsException } from "../exceptions/auth.exceptions";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  user: User;

  get isLoggedIn(): boolean {
    return this.user != null;
  }

  constructor(private router: Router) {
    if (localStorage.getItem("mdp-user")) {
      this.user = JSON.parse(localStorage.getItem("mdp-user"));
    }
  }

  async login(username, password): Promise<User> {
    if (username === "ifa" && password === "Start123") {
      this.user = {
        username,
        firstName: "Petra",
        lastName: "Mustermann",
        email: "ifa@omm-solutions.de",
        roles: ["USER", "ADMIN"]
      };
      localStorage.setItem("mdp-user", JSON.stringify(this.user));
      return this.user;
    }

    throw new InvalidCredentialsException();
  }

  async logout(): Promise<boolean> {
    this.user = null;
    localStorage.removeItem("mdp-user");
    this.router.navigate(["/login"]);
    return true;
  }
}
