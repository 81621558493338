import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ViewLoginComponent } from "./views/view-login/view-login.component";
import {ViewMainComponent} from "./views/view-main/view-main.component";
import {IsLoggedInGuard} from "./guards/is-logged-in-guard.service";

const routes: Routes = [
  { path: "", component: ViewMainComponent, canActivate: [IsLoggedInGuard] },
  { path: "login", component: ViewLoginComponent },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
