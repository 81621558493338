<div class="header">
  <div *ngIf="!authService.isLoggedIn" class="not-logged-in">
    <img class="logo" src="../assets/logo_ifa.svg">
    <h1>Das ifa</h1>
    <h2>vermittelt, verändert, verbindet mit der MDP</h2>
  </div>
  <div *ngIf="authService.isLoggedIn" fxLayout="row" fxFill>
    <img class="logo" src="../assets/logo_ifa.svg">
    <div class="toolbar" fxLayout="row" fxFlex fxLayoutAlign="end">
      <button mat-button aria-label="Magazin" color="accent">
        <mat-icon>chrome_reader_mode</mat-icon>
        Magazin
      </button>
      <button mat-button aria-label="Magazin" color="accent">
        <mat-icon>event</mat-icon>
        Events
      </button>
      <button mat-button aria-label="Magazin" color="accent">
        <mat-icon>person</mat-icon>
      </button>
      <button mat-button aria-label="Logout" color="accent" (click)="authService.logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
